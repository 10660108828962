import React from 'react';
import PropTypes from 'prop-types';
import { Link } from "gatsby"

import MenuItem from '@material-ui/core/MenuItem';

const LinkMenuItem = React.forwardRef(({ to, children, ...rest }, ref) => (
  <MenuItem ref={ref} component={Link} to={to} {...rest}>
    {children}
  </MenuItem>
));



LinkMenuItem.propTypes = {
  to: PropTypes.string.isRequired,
  children: PropTypes.string.isRequired,
};

export default LinkMenuItem;