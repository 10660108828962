import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby"
import Img from "gatsby-image";
import { makeStyles } from "@material-ui/core/styles";
import { AppBar, Hidden, Toolbar, Menu } from "@material-ui/core"
import { Button } from "gatsby-theme-material-ui";
import MenuIcon from '@material-ui/icons/Menu';
import LinkMenuItem from "../components/linkmenuitem"

const useStyles = makeStyles((theme) => ({
  offset: theme.mixins.toolbar,
  toolbarGrid: {
    width: '100%',
    display: 'flex',
    alignItems: 'center'
  },
  toolbarSpacer:{
    flexGrow: 1
  },
  link:{
      outline:'none',
      border:0,
      padding:0,
      margin:0,
      backgroundColor:'red',
      display:'block',
  },
}));


export default function Header(props) {
  const classes = useStyles();
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          pages {
            name
            link
          }
        }
      }
      file(relativePath: { eq: "signature.png" }) {
        childImageSharp {
          small: fixed(height: 48) {
            ...GatsbyImageSharpFixed
          }
          large: fixed(height: 64) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `);
  const links = data.site.siteMetadata.pages;

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  if (!props.spacerOnly) {
    return (
      <React.Fragment>
        <AppBar position="fixed" elevation={0}>
          <Toolbar>
            <div className={classes.toolbarGrid}>
              <Hidden xsDown>
                <Link to='/'>
                  <Img fixed={data.file.childImageSharp.large} alt={data.site.siteMetadata.title} style={{display:"block"}}/>
                </Link>
                <div className={classes.toolbarSpacer}/>
                {
                  links.map((e, i) => <Button key={i.toString()} color="inherit" to={e.link}>{e.name}</Button>)
                }
              </Hidden>
              <Hidden smUp>
                <Link to='/'>
                  <Img fixed={data.file.childImageSharp.small}/>
                </Link>
                <div className={classes.toolbarSpacer}/>
                <MenuIcon onClick={handleClick} fontSize="large"/>
              </Hidden>
            </div>
          </Toolbar>
        </AppBar>
        <Menu
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          {
            links.map((e, i) => <LinkMenuItem key={i.toString()} to={e.link} onClick={handleClose}>{e.name}</LinkMenuItem>)
          }
        </Menu>
        <div className={classes.offset}/>
      </React.Fragment>
    );
  }else{
    return <div className={classes.offset}/>
  }
}
Header.defaultProps = {
  spacerOnly: false
}